import * as React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'
import { Link } from 'gatsby'

import { heights, dimensions, colors } from '../styles/variables'
import Container from './Container'
import styles from './styles.module.css'

const StyledHeader = styled.header`
  height: ${heights.header}px;
  padding: 0 ${dimensions.containerPadding}rem;
  background-color: ${colors.brand};
  color: ${transparentize(0.5, colors.white)};
`

const HeaderInner = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`

const HomepageLink = styled(Link)`
  color: ${colors.white};
  font-size: 1.5rem;
  font-weight: 600;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const MenuLink = styled(Link)`
  color: ${colors.white};
`

interface HeaderProps {
  title: string
  menuLinks: [{ name: string; link: string }]
}

const Header: React.FC<HeaderProps> = ({ title, menuLinks }) => (
  <StyledHeader>
    <HeaderInner>
      <HomepageLink to="/">{title}</HomepageLink>
      <div>
        <nav className={styles.headNav}>
          <ul style={{ display: 'flex' }}>
            {menuLinks.map(link => (
              <li
                key={link.name}
                style={{
                  listStyleType: `none`,
                  padding: `1rem`
                }}
              >
                <MenuLink to={link.link}>{link.name}</MenuLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </HeaderInner>
  </StyledHeader>
)

export default Header
